html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  color: #696969;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}

.fade-slide-up-appear {
  opacity: 0.01;
  transform: translate(0px, 10px);
}

.fade-slide-up-appear-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: 0.5s ease-in all;
}

.fade-slide-down-appear {
  opacity: 0.01;
  transform: translate(0px, -10px);
}

.fade-slide-down-appear-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: 0.5s ease-in all;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear-active {
  opacity: 1;
  transition: 0.5s ease-in all;
}
